.internship-page {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.content-container {
  display: flex;
  background-color: #ffffff;
  flex: 1;
}

.referral-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 9px 16px;
  border-radius: 4px;
  font-size: 18px;

  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.referral-button:hover {
  background-color: #0056b3;
}


.main-content {
  flex: 1;
  background-color: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search-bar {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #e3e2e2;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  
}

.search-bar:focus {
  border-color: #007bff;
}

.bannerintern{
  display: flex;
  align: center;
  size-adjust: 2px;
  width: 100%; height: 100%;
  margin-top: -22px;
  margin-bottom: 82px;
  object-fit:contain;
  border-radius: 23px;
  box-shadow: -3px -2px 40px #979797;
}

.filter-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.filter-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.job-listings {
  display: grid;
  gap: 20px;
}

.job-card {
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  margin-bottom: 20px;
}

.job-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.job-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.company-logo {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
}

.job-info {
  flex: 1;
  margin-left: 10px;
}

.job-info h3 {
  margin: 0;
  font-size: 1.25rem;
  color: #333;
  font-weight: 500;
}

.job-info .company {
  font-size: 0.875rem;
  color: #666;
}

.job-tags {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.tag {
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.advanced {
  background-color: #ffe7e7;
  color: #ff0000;
}

.remote {
  background-color: #e7f0ff;
  color: #007bff;
}

.skills {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  flex-wrap: wrap;
}

.skill {
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.875rem;
}

.job-details {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin: 10px 0;
}

.job-details p {
  font-size: 0.875rem;
  color: #666;
  margin: 0;
}

.job-details span {
  font-weight: 500;
  color: #333;
  display: block;
}

.job-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0;
}

.high-applicants {
  color: #007bff;
  font-size: 0.875rem;
}

.apply-info {
  color: #666;
  font-size: 0.75rem;
}

.job-actions {
  display: flex;
  gap: 10px;
}

.job-actions .view-details,
.job-actions .apply-now,
.job-actions .share-bt {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 0.875rem;
  background-color: #007bff;
}

.job-actions .view-details {
  color: #ffffff;
  background-color: #007bff;
  font-size: 0.995rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s;
}

.job-actions .view-details::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-45deg);
  transition: left 0.5s ease-in-out;
}

.job-actions .view-details:hover::before {
  left: 100%;
}


.view-details-hr {
  color: #ffffff;
  background-color: #ff1c07;
  font-size: 0.995rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.view-details-hr::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  transform: skewX(-45deg);
  transition: left 0.5s ease-in-out;
}

.view-details-hr:hover::before {
  left: 100%;
}


.job-actions .view-details:hover {
  background-color: #0a52ce;
  transform: translateY(-2px);
}

.job-actions .apply-now {
  background-color: #603bf4;
}

.job-actions .apply-now:hover {
  background-color: #3e11f3;
  transform: translateY(-2px);
}

.job-actions .share-bt {
  background-color: #fff;
  color: #007bff;
  border: 1px solid #007bff;
}

.job-actions .share-bt:hover {
  background-color: #e7f0ff;
  transform: translateY(-2px);
}

.blue-strip {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  font-size: 0.875rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
  background: linear-gradient(270deg, #ff1493, #00ff7f, #1e90ff, #ff4500);
  background-size: 800% 800%;
  animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
    
  }

  .job-details {
    grid-template-columns: 1fr;

  }

  .job-card {
    padding: 15px;
    
    margin-bottom: 15px;
    width: 110%; /* Ensure job cards take full width */
  }

  .search-container {
    flex-direction: column;
    margin-right: -62px;
    margin-left: -74px; /* Adjusted to match margin-right */
    width: calc(100% + 46px); /* Adjusted width to accommodate margins */
}


  .filter-button {
    width: 100%;
    margin-top: -20px;
    margin-right: -90px;
    margin-bottom: 23px;

  }

  .company-logo {
    width: 50px;
    height: 50px;
  }

  .job-info h3 {
    font-size: 1rem;
  }

  .job-info .company {
    font-size: 0.75rem;
    margin-top: -4px;
    margin-bottom: -15px;
    margin-bottom: -15px;



  }

  .skill {
    font-size: 0.75rem;
  }

  .job-details p {
    font-size: 0.75rem;
  }

  .high-applicants {
    font-size: 0.75rem;
  }

  .apply-info {
    font-size: 0.625rem;
  }

  

  .job-actions .view-details,
  .job-actions .apply-now,
  .job-actions .share-bt {
    padding: 8px 15px;
    margin-top: -6px;
    font-size: 0.99rem;
  }

  .blue-strip {
    font-size: 0.85rem;
    margin-top: 20px;
    padding-top: 4px;
    padding-bottom: 4px;

  }

  /* Modify job-listings to take full width on mobile devices */
  .job-listings {
    grid-template-columns: 1fr; /* Single column layout */
    gap: 20px;
    width: 300px;
    margin-left: -200px;
    background-color: #fff;

     /* Full width */
  }


  .search-bar {
    flex: 1;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #e3e2e2;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
    max-width:700%; /* Limit maximum width */
    font-size: 19px;
    margin-right: -340px;
    margin-top: 50px;
    margin-left: -460px;
  }
  
  .job-card {
    margin: 0; /* Remove margin */
    padding: 20px; /* Adjust padding */
    margin-left:-45px;
    margin-right:-2px;
    margin-top: -7px;
    background-color: #fff;



  }


  .filters {
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .filters h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.25rem;
    color: #0095ff;
    text-align: center;
    font-weight: 600;
  }
  
  .filters label {
    font-size: 0.875rem;
    color: #333;
    margin-bottom: 5px;
    display: block;
  }
  
  .filters select,
  .filters input[type="range"] {
    width: 100%;
    padding: 10px;
    font-size: 0.875rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    outline: none;
  }
  
  .filters input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 6px;
    background: #ddd;
    border-radius: 5px;
    outline: none;
  }
  
  .filters input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #603bf4;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .filters input[type="range"]::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #603bf4;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .filters .filter-options {
    display: flex;
    flex-direction: column;
  }
  
  .filters .filter-option {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .filters .filter-option input[type="checkbox"] {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  
  .filters .apply-filters-btn {
    padding: 10px;
    background-color: #009dff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
  }
  
  .filters .apply-filters-btn:hover {
    background-color: #0392ff;
    transform: translateY(-2px);
  }

/* Mobile Device Styles */
@media (max-width: 767px) {
  .tab-container {
    display: flex;
    justify-content: center; /* Center align the tabs */
    margin-top: 40px;
    margin-bottom: -50px; /* Adjust margin as needed */
    margin-left: -210px;

  }

  .tab {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 0 5px; /* Add horizontal margin for spacing between tabs */
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .tab.active {
    border-color: blue;
    color: blue;
  }
}




}

/* Filters CSS */
.filters {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 0 auto;
  box-sizing: border-box;
}

.filters h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: #0091ff;
  text-align: center;
  font-weight: 600;
}

.filters label {
  font-size: 0.875rem;
  color: #333;
  margin-bottom: 5px;
  display: block;
}

.filters select,
.filters input[type="range"] {
  width: 100%;
  padding: 10px;
  font-size: 0.875rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 20px;
  outline: none;
}

.filters input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
}

.filters input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #603bf4;
  border-radius: 50%;
  cursor: pointer;
}

.filters input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #00aeff;
  border-radius: 50%;
  cursor: pointer;
}

.filters .filter-options {
  display: flex;
  flex-direction: column;
}

.filters .filter-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filters .filter-option input[type="checkbox"] {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.filters .apply-filters-btn {
  padding: 10px;
  background-color: #0091ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
}

.filters .apply-filters-btn:hover {
  background-color: #05c9ff;
  transform: translateY(-2px);
}

.clear-filters{
  background-color: #0091ff;
  color: #ffffff;
}

/* Popup container */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  padding: 0px;
}

/* Popup header */
.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;

}

.popup-content h2 {
  margin-top: 0;
  color: #000000;
}

/* Social media icons */
.share-icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
}

.share-icons img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* Text area for message */
textarea {
  width: 90%;
  height: 60px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
}

.blue-button {
  background-color: blue;
  color: white;
  border: none; /* Optional: remove the border */
  padding: 10px 20px; /* Optional: add some padding */
  cursor: pointer; /* Optional: change the cursor to pointer */
  border-radius: 4px; /* Optional: add rounded corners */
  font-size: 16px; /* Optional: adjust the font size */
}

/* Optional: Add hover effect */
.blue-button:hover {
  background-color: darkblue;
}

/* Link container */
.link-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.link-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

/* Copy link button */
.copy-link {
  background-color: #004dff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

/* Bottom banner */
.bottom-banner {
  background-color: #ac4af1;
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: 15px;
  border-radius: 0 0 10px 10px;
}

/* Close button */
.close {
  position: absolute;
  color: rgb(1, 1, 1);
  background-color: #fdfefd;

  top: 20px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* Link copied notification */
.copy-notification {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}



/* Add these styles to hide filters initially and style the filter button */
@media (max-width: 768px) {
  .filters {
    display: none; /* Hide filters by default on mobile */
    position: absolute;
    top: 70px; /* Adjust as needed */
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: -20px;
    margin-right: -145px;
  }



  .bannerintern{
    display: flex;
    align-items: center;
    margin-top: -42px;
    margin-bottom: 52px;
    object-fit:contain;
    margin-left: -245px;
    margin-right: 13px;
    width:350%;
    height:100%;
    border-radius: 23px;
    box-shadow: -3px -2px 40px #979797;
  
  }
/* Reduce size of filter icon image */
.filter-button::before {
  content: '';
  display: inline-block;
  width: 20px;  /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  background-image: url('./assets/icons/setting.png');
  background-size: contain; /* Ensures the image is contained within the specified width and height */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center; /* Centers the image within the element */
}

/* Initially hide filters */
.filters {
  display: none;
}

/* Show filters when .filters-active class is added to the main content */
.filters-active .filters {
  display: block;
}





/* Popup header */
.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10;


}

.popup-content h2 {
  margin-top: 0;
  color: #000000;
}

/* Social media icons */
.share-icons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
}

.share-icons img {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* Text area for message */
textarea {
  width: 90%;
  height: 60px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
}

.blue-button {
  background-color: blue;
  color: white;
  border: none; /* Optional: remove the border */
  padding: 10px 20px; /* Optional: add some padding */
  cursor: pointer; /* Optional: change the cursor to pointer */
  border-radius: 4px; /* Optional: add rounded corners */
  font-size: 16px; /* Optional: adjust the font size */
}

/* Optional: Add hover effect */
.blue-button:hover {
  background-color: darkblue;
}

/* Link container */
.link-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.link-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
}

/* Copy link button */
.copy-link {
  background-color: #004dff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

/* Close button */
.close {
  position: absolute;
  color: rgb(1, 1, 1);
  background-color: #fdfefd;

  top: 20px;
  right: -40px;
  font-size: 24px;
  cursor: pointer;
}


} 