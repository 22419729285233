/* Reverting all changes except for the iframe CSS */

.person-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .profile-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .connect-btn {
    display: block;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    background: #0077b5;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .connect-btn:hover {
    background: #005582;
  }
  
  .data-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .data-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    width: calc(33.333% - 40px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .data-item:hover {
    transform: translateY(-5px);
  }
  
  .data-item .profile-img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .person-info {
    text-align: center;
  }
  
  .view-post-btn {
    display: block;
    text-align: center;
    margin-top: 10px;
    padding: 8px;
    background: #0077b5;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .view-post-btn:hover {
    background: #005582;
  }
  
  /* Updated iframe styling for mobile responsiveness */
  iframe {
    width: 100%;
    max-width: 400px;
    height: 700px;
    border: none;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .data-item {
      width: calc(50% - 40px);
    }
  }
  
  @media (max-width: 480px) {
    .data-item {
      width: calc(100% - 40px);
    }
  
    .connect-btn,
    .view-post-btn {
      font-size: 0.9rem;
      padding: 8px;
    }
  }
  /* Reverting all changes except for the iframe CSS */
  
  .person-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .profile-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 20px;
  }
  
  .connect-btn {
    display: block;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    background: #0077b5;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .connect-btn:hover {
    background: #005582;
  }
  
  .data-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .data-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    width: calc(33.333% - 40px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .data-item:hover {
    transform: translateY(-5px);
  }
  
  .data-item .profile-img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .person-info {
    text-align: center;
  }
  
  .view-post-btn {
    display: block;
    text-align: center;
    margin-top: 10px;
    padding: 8px;
    background: #7d76ff;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .view-post-btn:hover {
    background: #582aff;
  }
  
  /* Updated iframe styling for mobile responsiveness */
  iframe {
    width: 100%;
    max-width: 400px;
    height: 700px;
    border: none;
    margin-top: 20px;
  }
  
  @media (max-width: 768px) {
    .data-item {
      width: calc(50% - 40px);
    }
  }
  
  @media (max-width: 480px) {
    .data-item {
      width: calc(100% - 40px);
    }
  
    .connect-btn,
    .view-post-btn {
      font-size: 0.9rem;
      padding: 8px;
    }
  }
  