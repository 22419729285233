.refer-and-earn-page {
  display: flex;
  font-family: Arial, sans-serif;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  min-height: 100vh;
}

.refer-and-earn-content {
  margin-left: 200px; /* Adjust this value according to your sidebar width */
  width: calc(100% - 250px); /* Ensure the main content takes the remaining width */
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.refer-and-earn-header {
  background-color: #ffffff;
  padding: 20px;
}

.refer-and-earn-header h1 span {
  color: red;
}

.refer-and-earn-image {
  margin-top: 20px;
  display: flex; /* Add this line to make the image centered */
  justify-content: center; /* Add this line to make the image centered */
}

.refer-and-earn-icon {
  width: 50%;
  /* Remove the following two lines */
  /* align-items: center; */
  /* margin-right: 30px; */
}

.refer-and-earn-terms {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.refer-and-earn-terms-content,
.refer-and-earn-redeem {
  width: 40%;
}

.refer-and-earn-redeem-button {
  margin-top: 10px;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}

.refer-and-earn-link {
  margin-top: 50px;
  margin-left: 600px;
}

.refer-and-earn-copy-button {
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  margin-left: 40px;
  cursor: pointer;
}

.refer-and-earn-leaderboard {
  margin-top: 50px;
}

.refer-and-earn-leaderboard-table {
  width: 80%;
  margin: 0 auto;
  border-collapse: collapse;
}

.refer-and-earn-leaderboard-table th,
.refer-and-earn-leaderboard-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.refer-and-earn-rewards {
  margin-top: 50px;
}

.refer-and-earn-reward-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.refer-and-earn-reward-card {
  width: 200px;
  text-align: center;
  margin-bottom: 20px;
}

.refer-and-earn-reward-card img {
  max-width: 100%;
  height: auto;
}

.refer-and-earn-reward-card button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: blue;
  color: white;
  border: none;
  cursor: pointer;
}

/* HOW TO REFER section styles */
.refer-and-earn-how-to-refer {
  margin-top: 50px;
  text-align: left;
  padding: 0 10%;
}

.refer-and-earn-how-to-refer h2 {
  text-align: center;
}

.refer-and-earn-how-to-refer ol {
  padding-left: 20px;
}

.refer-and-earn-scroll-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}

.refer-and-earn-scroll-button {
  margin: 0 30px;
  padding: 15px 0;
  margin-top: 13px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #555;
  border-bottom: 2px solid transparent;
  margin-bottom: 4px;
}

.refer-and-earn-scroll-button.active {
  color: blue;
  border-color: blue;
}

  /* Mobile Responsive Styles */
@media (max-width: 768px) {
  .refer-and-earn-page {
    flex-direction: column; /* Stack the elements vertically */
  }

  .refer-and-earn-content {
    margin-left: 0; /* Remove margin */
    width: 100%; /* Full width */
    padding: 10px; /* Adjust padding for mobile */
  }

  .refer-and-earn-header {
    padding: 15px;
    text-align: center; /* Center align the text */
  }

  .refer-and-earn-icon {
    width: 80%; /* Adjust the image width */
  }

  .refer-and-earn-terms {
    flex-direction: column; /* Stack terms and redeem sections vertically */
    align-items: center;
  }

  .refer-and-earn-terms-content,
  .refer-and-earn-redeem {
    width: 90%; /* Full width with some padding */
    margin-top: 20px; /* Add margin for spacing */
  }

  .refer-and-earn-redeem-button {
    width: 100%; /* Full width button */
    padding: 10px;
  }

  .refer-and-earn-link {
    margin-top: 20px;
    margin-left: 0; /* Remove left margin */
    text-align: center; /* Center align */
  }

  .refer-and-earn-copy-button {
    width: 100%; /* Full width button */
    padding: 10px;
    margin-top: 10px; /* Add margin for spacing */
    margin-left: 0; /* Remove left margin */
  }

  .refer-and-earn-leaderboard-table {
    width: 100%; /* Full width table */
  }

  .refer-and-earn-rewards {
    padding: 0 10px; /* Add padding for mobile */
  }

  .refer-and-earn-reward-cards {
    flex-direction: column; /* Stack reward cards vertically */
    align-items: center; /* Center align the cards */
  }

  .refer-and-earn-reward-card {
    width: 80%; /* Adjust width for mobile */
    margin-bottom: 20px;
  }

  .refer-and-earn-how-to-refer {
    padding: 0 5%; /* Adjust padding for mobile */
  }

  .refer-and-earn-scroll-buttons {
    flex-direction: column; /* Stack scroll buttons vertically */
    align-items: center; /* Center align */
  }

  .refer-and-earn-scroll-button {
    margin: 10px 0; /* Add vertical margin */
    padding: 10px 0; /* Adjust padding */
  }
}