/* Prevent scrolling on the entire page */
html, body {
   
    height: 100%;
    margin: 0;
    padding: 0;
}

/* General container for the auth pages */
.auth-container {
    display: flex;
    height: 100vh;
    justify-content: flex-end; /* Align to the right */
    align-items: center;
    position: relative;
    background-color: #ffffff;
}

/* Container for the image section */
.auth-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 102%;
    height: 110%;
    margin-top: -20px;
    margin-left: -20px;
    background-image: url('./components/assets/icons/g.png'); /* Update this path */
    background-size: cover; /* Ensure the image covers the entire background */
    background-position: center;
    z-index: 0;
}
  
/* Container for the form section */
.auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 1;
    width: 100%;
    max-width: 30%; /* Constrain the form container to a third of the screen */
    height: 100%;
}
  
/* Form styling */
.auth-form {
    width: 100%;
    max-width: 400px;
    background-color: #fffdfd; /* Remove transparency */
    padding: 35px; /* Reduce padding to decrease height */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
}
  
/* Heading styling */
.auth-form h2 {
    margin-bottom: 20px; /* Reduce margin to decrease height */
    font-size: 24px;
    color: #333;
}
  
/* Input field styling */
.auth-form input {
    width: 90%;
    padding: 9px; /* Reduce padding to decrease height */
    margin: -2px 0; /* Reduce margin to decrease height */
    border: 1px solid #c0c0c0;
    border-radius: 4px;
    font-size: 15px;
}
  
/* Button styling */
.auth-form button {
    width: 100%;
    padding: 10px; /* Reduce padding to decrease height */
    margin-top: 15px; /* Reduce margin to decrease height */
    background-color: #001f6e;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
/* Button hover effect */
.auth-form button:hover {
    background-color: #0044cc;
}
  
/* Google button styling */
.google-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px; /* Reduce padding to decrease height */
    margin-top: 15px; /* Reduce margin to decrease height */
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}
  
/* Google button hover effect */
.google-btn:hover {
    background-color: #f1f1f1;
}
  
/* Styling for the Google button image */
.google-btn img {
    margin-right: 8px;
}
  
/* Forgot password link styling */
.forgot-password-link {
    display: block;
    margin-top: 8px; /* Reduce margin to decrease height */
    color: #001f6e;
    text-decoration: none;
}
  
.forgot-password-link:hover {
    text-decoration: underline;
}
  
/* Signup link styling */
.signup-link {
    color: #001f6e;
    text-decoration: none;
}
  
.signup-link:hover {
    text-decoration: underline;
}
  
/* Footer text styling */
.login-footer-text {
    margin-top: 15px; /* Reduce margin to decrease height */
    font-size: 14px;
    color: #333;
}





/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .auth-container {
        justify-content: center; /* Center the container on smaller screens */
    }

    .auth-image {
        display: none; /* Hide the image on smaller screens */
    }

    .auth-form-container {
        max-width: 110%; /* Allow the form container to take up the full width on smaller screens */
        height: 100vh; /* Ensure the form container covers the full height */
    }

    .auth-form {
        padding: 20px; /* Adjust padding for smaller screens */
        max-width: 100%; /* Ensure the form does not exceed screen width */
        height: 100%; /* Ensure the form covers the full height */
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .auth-form h2 {
        font-size: 20px; /* Reduce font size for smaller screens */
        margin-bottom: 15px; /* Adjust margin for smaller screens */
    }

    .auth-form input {
        width: 100%; /* Ensure inputs take up full width */
        padding: 8px; /* Adjust padding for smaller screens */
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .auth-form button {
        padding: 8px; /* Adjust padding for smaller screens */
        font-size: 14px; /* Adjust font size for smaller screens */
        margin-top: 10px; /* Adjust margin for smaller screens */
    }

    .google-btn {
        padding: 8px; /* Adjust padding for smaller screens */
        font-size: 14px; /* Adjust font size for smaller screens */
        margin-top: 10px; /* Adjust margin for smaller screens */
    }

    .forgot-password-link, .login-footer-text {
        font-size: 12px; /* Adjust font size for smaller screens */
        margin-top: 10px; /* Adjust margin for smaller screens */
    }
}


    