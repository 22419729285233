/* App.css */

/* Base styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}

.navbar .logo {
  height: 40px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-right > * {
  margin-left: 20px;
}

.navbar-right .profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

/* Sidebar */
.sidebar {
  width: 200px;
  background-color: #fff;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.sidebar button,
.sidebar ul {
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

/* Main content area */
.main-content {
  display: flex;
}

.content {
  padding: 20px;
  flex-grow: 1;
}

.trending,
.jobs {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.trending-cards,
.job-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card,
.job-card {
  flex: 1;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.card img,
.job-card img {
  max-width: 100%;
  height: auto;
  border-radius: 50%;
}

/* Media Queries */
@media (max-width: 767px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .navbar-right {
    flex-direction: column;
    align-items: center;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
  }

  .main-content {
    flex-direction: column;
  }

  .content {
    padding: 10px;
  }

  .trending-cards,
  .job-cards {
    flex-direction: column;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .navbar {
    flex-direction: row;
  }

  .navbar-right {
    flex-direction: row;
  }

  .sidebar {
    width: 150px;
  }

  .content {
    padding: 15px;
  }

  .trending-cards,
  .job-cards {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .navbar {
    flex-direction: row;
  }

  .navbar-right {
    flex-direction: row;
  }

  .sidebar {
    width: 200px;
  }

  .content {
    padding: 20px;
  }

  .trending-cards,
  .job-cards {
    flex-direction: row;
  }
}
