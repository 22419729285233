/* Combined CSS for Jobs and Trending */
.CombinedPage {
  display: flex;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  min-height: 100vh;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Jobs Section */
.jobs-container {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
    min-height: 100vh;
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  
  .jobs-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #f9f9f9;
    overflow: hidden;
  }
  
  .jobs-content h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .job-list {
    display: flex;
    overflow: hidden;
    position: relative;
    width: calc(100% - 250px); /* Adjust this value based on your sidebar width */
    animation: scroll 9s linear infinite;
  }
  
  .job-item {
    min-width: 200px;
    margin: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.1s;
  }
  
  .job-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .job-item p {
    margin: 5px 0;
    font-size: 1em;
    color: #666;
  }
  
  .job-item:hover {
    transform: scale(1.05);
  }
  
  .int {
    text-align: center;
    margin-top: 20px;
  }
  
  .int h1 {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
  }
  
  .int button {
    background-color: #007BFF; /* Bootstrap primary color */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.1s;
  }
  
  .int button:hover {
    background-color: #0056b3; /* Darker shade of primary color */
  }
  
 /* Trending Section */
.trending-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: #f9f9f9;
  overflow: hidden; /* Prevent horizontal scrolling */
}

.trending-item-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: calc(50% - 40px); /* Adjust based on your design */
  margin: 380px;
  margin-top: 23px;
  margin-bottom: 23px;

  text-align: center;
  transition: transform 0.3s;
}

.trending-item-container:hover {
  transform: translateY(-10px);
}

.trending-item-container h2 {
  font-size: 1.5em;
  color: #333;
}

.trending-item-container img {
  width: 100%;
  max-width: 200px; /* Adjust image max-width */
  height: auto;
  border-radius: 10px;
  margin: 20px 0;
}

.trending-item-container p {
  font-size: 1em;
  color: #666;
}

.trending-item-container button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.trending-item-container button:hover {
  background-color: #0056b3;
}
  
  /* Media Queries for Responsiveness */
  @media (max-width: 1200px) {
    .trending-container {
      flex-direction: column;
      align-items: center;
      padding: 20px;
      margin-bottom: 30px;
    }
  
    .trending-item-container {
      width: calc(50% - 30px); /* Adjust width for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .jobs-container {
      flex-direction: column;
    }
  
    .jobs-content {
      padding: 10px;
    }
  
    .jobs-content h2 {
      font-size: 1.5em;
    }
  
    .job-list {
      width: 100%;
      overflow-x: auto;
      padding: 10px 0;
    }
  
    .job-item {
      min-width: 150px;
      margin: 10px 5px;
    }
  
    .trending-container {
      flex-direction: column;
      align-items: center;
      padding: 10px;
      margin-right: 20px;

    }
  
    .trending-item-container {
      width: calc(50% - 20px); /* Adjust width for smaller screens */
      padding: 15px;
    }
  
  
    .trending-item-container button {
      padding: 8px 16px;
    }
  }
  
  @media (max-width: 480px) {
    .trending-container {
      flex-direction: column;
      align-items: center;
      width: 100%; /* Ensure container takes full width */
      padding: 10px;
    }
  
    .trending-item-container {
      width: calc(100% - 20px); /* Full width on very small screens */
      margin: 10px;
    }
  
    .trending-item-container img {
      width: 100%; /* Make images full width on small screens */
    }
  
    .trending-item-container button {
      padding: 6px 12px;
      font-size: 14px;
    }
  }
  