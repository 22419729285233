/* Existing Styles */
/* Gradient Animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

html, body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  height: 100%;
  background-color: #111;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.full-width-segment {
  max-width: 100%;
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.landing-page {
  position: relative;
  text-align: center;
  padding: 0;
  background-color: #f7f7f7;
  overflow: hidden; /* Ensure the video stays within the bounds */
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  pointer-events: none; /* Prevent interaction with the video */
}

.text-container {
  margin-bottom: 50px;
  position: relative; /* Ensure it stays above the video */
  z-index: 1;
}

.text-container h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.text-container p {
  font-size: 19px;
  margin-bottom: 20px;
}

.link {
  background-color: #ff5722;
  color: white;
  padding: 12px 23px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
}

.link:hover {
  background-color: #e64a19;
}

.stats-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  position: relative; /* Ensure it stays above the video */
  z-index: 1;
}

.stat {
  background-color: rgb(253, 253, 253);
  margin-bottom: 90px;
  margin-top: 50px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  text-align: center;
  width: 200px;
}

.stat h2 {
  font-size: 28px;
  margin: 0 0 10px 0;
}

.how-it-helps {
  text-align: center;
  padding: 50px 0;
  background-color: #000000;
}

.how-it-helps h2 {
  font-size: 50px;
  margin-bottom: 36px;
  background: linear-gradient(270deg, #ff1493, #00ff7f, #1e90ff, #ff4500);
  background-size: 800% 800%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 5s ease infinite;
}

.help-cards {
  display: flex;
  justify-content: center;
  gap: 25px;
  flex-wrap: wrap;
}

.help-card {
  background-color: rgb(255, 255, 255);
  padding: 23px;
  border-radius: 11px;
  box-shadow: 0 0 19px rgba(0,0,0,0.1);
  text-align: center;
  width: 200px;
}

.help-card h3 {
  font-size: 22px;
  margin: 10px 0;
}

.help-card p {
  font-size: 17px;
}

.refe {
  display: flex;
  align-items: center;
  margin-top: 62px;
  margin-bottom: 22px;
  object-fit:contain;
  margin-left: 210px;
  margin-right: -23px;
  width:70%;
  height:70%;
  border-radius: 23px;
  box-shadow: 10px 10px 20px #000000;
}

.partners-section {
  text-align: center;
  padding: 50px 0;
  background-color: #f7f7f7;
}

.partners-logos {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.partners-logos img {
  width: 40px;
  height: auto;
}

.footer {
  background-color: #241515;
  padding: 20px 0;
  color: white;
}

.footer-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;
}

.footer-logo {
  text-align: center;
}

.footer-logo img {
  width: 80px;
}

.footer-links {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-links div {
  margin: 10px;
}

.footer-links h3 {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin: 5px 0;
  display: block;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer p {
  text-align: center;
  margin-top: 20px;
}





/* CSS for the new Job Roles section */
.job-roles-section {
  text-align: center;
  padding: 2rem 0;
  background-color: #f7f9fc;
}

.job-roles-section h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.job-roles-section p {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #555;
}

.job-roles {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 1rem;
}

.job-role {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  width: calc(25% - 2rem);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.job-role:hover {
  transform: scale(1.05);
}

.job-role h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.job-role p {
  font-size: 1rem;
  color: #777;
  margin-bottom: 1rem;
}

.job-role img {
  width: 100%;
  height: auto;
  max-height: 50px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .job-role {
    width: calc(50% - 2rem);
  }
}

@media (max-width: 480px) {
  .job-role {
    width: calc(100% - 2rem);
  }
}




@media (max-width: 768px) {

.homepage {
  flex-direction: column;
  margin-top: -37px;
  max-width: 233%;
  max-height: 12%;
 background-color: #000000;
  align-items: center;
}
  .text-container h1 {
    font-size: 28px;
  }

  .text-container p {
    font-size: 16px;
  }

  .homepage-title {
    font-size: 14px;
    flex-direction: column;
    margin-top: 32px;
    align-items: center;
    color: white;


  }
  .link {
    flex-direction: column;
    margin-top: -7px;
    align-items: center;
  }
  .stats-container {
    flex-direction: column;
    margin-top: 32px;
    align-items: center;
  }

  .stat {
    width: 60%;
    margin: 10px 0;
    margin-bottom: 10%;
  }

  .refe {
    display: flex;
    align-items: center;
    margin-top: 22px;
    margin-bottom: 13px;
    object-fit:contain;
    margin-left: -2px;
    margin-right: -23px;
    width:100%;
    height:100%;
    border-radius: 23px;
    box-shadow: 10px 10px 20px #000000;
  }
  

  .how-it-helps h2 {
    font-size: 36px;
  }

  .help-cards {
    flex-direction: column;
    align-items: center;
  }

  .help-card {
    width: 70%;
    margin: 10px 0;
  }

  .partners-logos img {
    width: 30px;
  }

  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-links div {
    margin: 10px 0;
  }
  .footer-hirespoof {
    font-size: 1em;
    font-weight: lighter;
    margin: -0.39em 0;
    margin-top: -0.5em;
  }


}

@media (max-width: 480px) {
  .text-container h1 {
    font-size: 24px;
  }

  .text-container p {
    font-size: 14px;
  }

  .stat h2 {
    font-size: 24px;
  }

  .how-it-helps h2 {
    font-size: 28px;
  }

  .partners-logos img {
    width: 20px;
  }

  .footer-logo img {
    width: 60px;
  }

  .footer p {
    font-size: 14px;
  }

  .homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #111;
    background-position-x:133ch;
    color: white;
  }

  .homepage-subtitle {
    font-size: 1em;
    font-weight: lighter;
    margin: 0.5em 0;
    margin-top: 33px;
    font-weight: bold;
    background: linear-gradient(270deg, #ff1493, #00ff7f, #1e90ff, #ff4500);
    background-size: 800% 800%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientAnimation 6s ease infinite;
  }

}

/* New Homepage Styles */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.homepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #111;
  color: white;
}

.homepage-title {
  font-size: 3em;
  font-weight: bold;
  margin: 0.5em 0;
  color: white;

}

.homepage-subtitle {
  font-size: 2em;
  font-weight: lighter;
  margin: 0.5em 0;
  font-weight: bold;
  background: linear-gradient(270deg, #ff1493, #00ff7f, #1e90ff, #ff4500);
  background-size: 800% 800%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 6s ease infinite;
}

.homepage-description {
  font-size: 1.2em;
  color: #aaa;
}

.homepage-button {
  background-color: #ff5722;
  color: white;
  border: none;
  padding: 1em 2em;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 1em;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.homepage-button:hover {
  background-color: #e64a19;
}

.footer-hirespoof {
  font-size: 3em;
  font-weight: lighter;
  margin: -0.39em 0;
  margin-top: -0.5em;
  background: linear-gradient(270deg, #e5e1e3, #9d9ea0, #727476, #362b27);
  background-size: 1000% 1000%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 2s ease infinite;
}

.footer-bottom {
  font-size: 1.6em;
  font-weight: lighter;
  margin: 1.3em 0;
  margin-bottom: 1.5em;
  background-color: #241313;
}
