@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Apply global styles */
body {
  margin: 0;
}

/* Dashboard container styles */
.dashboard {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif; /* Apply Roboto font */
}

/* Main content container styles */
.main-content {
  display: flex;
  flex: 1;
}

/* Content area styles */
.content {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa; /* Light gray background */
  border-radius: 8px; /* Add border radius for a softer look */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

/* Button styles */
button {
  padding: 10px 20px; /* Add padding to the buttons */
  border: none; /* Remove border */
  border-radius: 5px; /* Add border radius */
  background-color: #7e68fc;
  text-emphasis-color: white;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

/* Primary button styles */
.primary-btn {
  background-color: #7e68fc;
  color: #ecebeb; /* Text color */
}

/* Primary button hover styles */
.primary-btn:hover {
  background-color: #0056b3; /* Darker shade of primary color on hover */
}

/* Secondary button styles */
.secondary-btn {
  background-color: #7e68fc;
  color: #fff; /* Text color */
}

/* Secondary button hover styles */
.secondary-btn:hover {
  background-color: #788df4; /* Darker shade of gray on hover */
}

/* Success button styles */
.success-btn {
  background-color: #28a745; /* Green color for success button */
  color: #fff; /* Text color */
}

/* Success button hover styles */
.success-btn:hover {
  background-color: #218838; /* Darker shade of green on hover */
}

/* Danger button styles */
.danger-btn {
  background-color: #dc3545; /* Red color for danger button */
  color: #fff; /* Text color */
}

/* Danger button hover styles */
.danger-btn:hover {
  background-color: #c82333; /* Darker shade of red on hover */
}
