/* General Styles */
.random-container {
  font-family: 'Roboto', sans-serif;
  background-color: #1a1a1a;
  color: #fff;
  padding: 0 20px;
  max-width: 2500px;
  padding-right: 19px;
  margin: 0 auto;
}

/* Header Styles */
.random-header {
  padding: 50px 0;
  padding-right: 3px;
  text-align: center;
}

.random-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.random-logo {
  font-size: 24px;
  font-weight: bold;
}

.random-nav-links {
  display: flex;
}

.random-nav-link {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
}

.random-nav-link:hover {
  text-decoration: underline;
}

.random-header-content {
  text-align: center;
  margin: 20px 0;
}

.random-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.random-subtitle {
  font-size: 18px;
  margin-bottom: 40px;
}

.random-get-started-button {
  background-color: #0767bb;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.random-get-started-button:hover {
  background-color: #2880f3;
}

/* Pricing Section Styles */
.random-pricing-section {
  padding: 20px 0;
  text-align: center;
}

.random-pricing-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.random-pricing-subtitle {
  font-size: 18px;
  margin-bottom: 40px;
}

.random-pricing-container {
  text-align: center;
}

.random-price {
  font-size: 24px;
  margin-bottom: 10px;
}

.random-price-detail {
  font-size: 24px;
  color: #ccc;
}

.random-features-list {
  list-style-type: none;
  padding: 15px;
  margin: 50px 0 20px 0; /* Adjusted margin */
  text-align: center;
  display: inline-block;
}

.random-features-list li {
  margin-bottom: 10px;
}

.random-trial-button {
  background-color: #0767bb;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: block; /* Ensure it stays below the list */
  margin: 0 auto; /* Center the button */
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px; /* Add some space above the button */
}

.random-trial-button:hover {
  background-color: #32a3cf;



}

.random-trial-button:hover {
  background-color: #32a3cf;
}

/* Connect Section Styles */
.random-connect-section {
  padding: 50px 0;
  text-align: center;
}

.random-connect-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.random-connect-image {
  max-width: 70%;
  margin-right: 20px;
}

.random-connect-text {
  max-width: 600px;
}

.random-connect-title {
  font-size: 36px;
  margin-bottom: 20px;
}

.random-connect-description {
  font-size: 18px;
  margin-bottom: 40px;
}

.random-connect-button {
  background-color: #0767bb;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.random-connect-button:hover {
  background-color: #32a3cf;
}



/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
 

  .random-nav-bar {
    flex-direction: column;
    align-items: center;
  }

  .random-logo {
    margin-bottom: 10px;
  }

  .random-nav-links {
    flex-direction: column;
  }

  .random-nav-link {
    margin: 5px 0;
  }

  .random-title {
    font-size: 28px;
  }

  .random-subtitle {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .random-pricing-title,
  .random-connect-title {
    font-size: 28px;
  }

  .random-pricing-subtitle,
  .random-connect-description {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .random-connect-content {
    flex-direction: column;
  }

  .random-connect-image {
    max-width: 70%;
    margin-bottom: 20px;
    margin-right: 0;
  }

  .random-connect-text {
    max-width: 100%;
  }

  .random-container {
    padding: 0 10px;
  }
}