/* src/components/ShippingPolicy.css */
.shipping-policy {
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  .shipping-policy h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #0073e6;
  }
  
  .shipping-policy h3 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #005bb5;
  }
  
  .shipping-policy h4 {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 5px;
    color: #004080;
  }
  
  .shipping-policy p {
    margin-bottom: 15px;
  }
  
  .shipping-policy a {
    color: #0073e6;
    text-decoration: none;
  }
  
  .shipping-policy a:hover {
    text-decoration: underline;
  }
  