.other-jobs-page {
  display: flex;
  flex-direction: column;
}

.content-container {
  display: flex;
}

.main-content {
  flex: 1;
  padding: 20px;
}

.search-container {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-bottom: 20px;
}

.search-bar {
  flex: 1; /* Take remaining space */
  padding: 15px;
}

.filter-button {
  padding: 13px;
  margin-bottom: 15px; /* Add margin to separate from input */
  margin-left: 15px; /* Add margin to separate from input */
}

.job-listings {
  display: flex;
  flex-direction: column;
}

.job-card {
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skills span {
  margin-right: 5px;
  background-color: #f1f1f1;
  padding: 5px;
  border-radius: 3px;
}

.job-actions button {
  margin-right: 10px;
}

.filters {
  padding: 20px;
  border-left: 1px solid #ccc;
}
