/* General styling */
.job-details-page {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.company-logo {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}


/* Header styling */
.header {
  background: linear-gradient(to right, #4a90e2, #50b3a2);
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;


  position: relative;
}




.job-details-page .header {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.title-share-container {
  display: flex;
  align-items: center;
  
}

.title-share-container h2 {
  margin-right: 40px; /* Increase the margin to create more space */
}

.share-button {
  background-color: white; /* Set background to white */
  background-color: #f4f4f4;
  border-radius: 50%; /* Make the button round */
  cursor: pointer;
  font-size: 1.9rem;
  background-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 69px; /* Set width */
  height: 30px; /* Set height */
  padding: 50px; /* Optional: Add padding inside the button */
}

.share-button:hover {
  background-color: #5b595f;
  color: white; /* Change icon color on hover */
}


.header h2 {
  margin: 0;
  font-size: 28px;
}

.apply-info, .applicant-info {
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
}

.action-buttons {
  margin-top: 10px;
}

.apply-now-button, .share-button {
  background-color: #ff0808;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin-right: 9px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;  /* Ensure link looks like a button */
  display: inline-block;
  transition: background-color 0.3s ease;
}

.apply-now-button:hover, .share-button:hover {
  background-color: #e41f1f;
}


.tab-conte {
  display: none;
}

.share-button {
  background-color: #5b595f;
  border-radius: 16px;
  margin-left: 30px;
  padding: 9.7px;
  padding-top: 8px;



}

.share-button:hover {
  background-color: #6d6572;
}

/* Job info styling */
.job-info {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.job-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.summary-item {
  flex: 1;
  min-width: 220px;
  background: #f0f4f8;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.summary-item strong {
  display: block;
  font-size: 20px;
  color: #4a90e2;
}

.summary-item span {
  font-size: 14px;
  color: #777;
}

/* Section styling */
.description, .requirements, .responsibilities, .hiring-process {
  margin-bottom: 20px;
  padding: 20px;
  background: #f0f4f8;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h3 {
  border-bottom: 2px solid #4a90e2;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.requirements ul, .responsibilities ul, .hiring-process ul {
  list-style-type: disc;
  padding-left: 20px;
  line-height: 1.6;
}

/* Responsive styling */
@media (max-width: 768px) {
  .job-summary {
    flex-direction: column;
  }
  
  .summary-item {
    min-width: 100%;
  }

  .apply-now-button, .share-button {
    width: 100%;
    margin-bottom: 10px;
    font-size: 17px;
    padding: 8px 17px;
    margin-left: -12PX;
    width: 53%;
  }

 
/* Mobile Device Styles */
@media (max-width: 767px) {
  .tab-conte {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align the tabs */
    margin-top: 30px;
    margin-bottom: -30px; /* Adjust margin as needed */
  }

  .tabee {
    width: 90%; /* Full width with some padding */
    text-align: center;
    padding: 6px;
    margin-bottom: 9px; /* Space between buttons */
    border: 1px solid #ccc;
    color: rgb(247, 247, 250);
    font-weight: bold;
    border-radius: 6px;
    background-color: #3e9ed9; /* Example background color */
    cursor: pointer;
    transition: all 0.2s ease;
  }

  .tabee.active {
    border-color: #3e9ed9;
    color:#3e9ed9;
  }

  .whatsapp {
    margin-top: 10px; /* Adjust margin as needed to separate from other tabs */
    background-color: green; /* Example background color for WhatsApp button */
  }

  /* Button base styles */
  .tabee.whatsapp {
    display: inline-block;
    padding: 15px 20px;
    background-color: #25D366; /* WhatsApp green color */
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: bold;
    position: relative; /* Ensure position is relative for absolute positioning of pseudo-element */
    overflow: hidden; /* Hide overflow to prevent flashing light overflow */
    width: 90%; /* Full width with some padding */
  }

  /* Button hover effect */
  .tabee.whatsapp:hover {
    background-color: #128C7E;
  }

  /* Button active state (if needed) */
  .tabee.whatsapp.active {
    background-color: #075E54;
  }

  /* Flashing animation */
  @keyframes move-flash {
    0% {
      background-position: -100% 0; /* Start position off screen */
    }
    100% {
      background-position: 100% 0; /* End position off screen */
    }
  }

  /* Applying the animation to the button */
  .tabee.whatsapp {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 50%, transparent 50%, transparent 100%);
    background-size: 300% 100%; /* Double the width to accommodate left to right animation */
    background-position: -100% 0; /* Start off screen */
    animation: move-flash 0.8s linear infinite;
  }
}


}
