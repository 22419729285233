.partners-section {
    text-align: center;
    padding: 80px 0;
    background-color: #f7f7f7;
    overflow: hidden; /* Ensure logos don't overflow the container */
  }
  
  .partners-logos {
    display: flex;
    justify-content: flex-start; /* Align items to the start for animation */
    gap: 20px;
    margin-top: 30px;
    animation: move-logos 10s linear infinite; /* Apply animation */
  }
  
  .partners-logos img {
    width: 70px;
    height: auto;
    box-shadow: 10px 10px 79px #8f8f8f;

  }
  
  @keyframes move-logos {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

    @media (max-width: 767px) {
  
      .partners-logos {
        display: flex;
        justify-content:flex-start; /* Align items to stretch for even spacing */
        gap: 20px;
        margin-top: 30px;
        overflow: hidden; /* Prevent overflow during animation */
        animation: move-logos 10s linear infinite; /* Apply animation */
        box-shadow: 10px 10px 79px #8f8f8f;
  }
}