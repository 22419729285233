
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .navbar {
    width: 100%;
    background-color: #ffffff;
    color: white;
    padding: 15px;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .content-container {
    display: flex;
    flex-direction: row;
    margin-top: 60px; /* Adjust for the navbar height */
  }
  
  .sidebar {
    width: 200px;
    padding: 10px;
    background-color: #fcfff8;
    display: flex;
    border-left: -10px solid #ffffff;
    flex-direction: column;
    position: fixed;
    top: 70px; /* Adjust for the navbar height */
    height: calc(100% - 60px); /* Adjust for the navbar height */
  }
  
  .sidebar button {
    margin: 10px 0;
    padding: 10px;
    background-color: rgb(233, 233, 233);
    border: none;
    cursor: pointer;
  }
  
  .sidebar .active {
    background-color: #853bf5;
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    margin-left: 230px; /* Adjust for the sidebar width */
  }
  
  .search-bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .search-bar input {
    width: 80%;
    padding: 10px;
  }
  
  .search-bar button {
    padding: 10px;
  }
  
  .job-listings {
    display: flex;
    flex-direction: column;
  }
  
  .job-card {
    border: 1px solid #e1e1e1;
    padding: 20px;
    margin-bottom: 20px;
    background-color: rgb(255, 255, 255);
  }
  
  .job-card h3 {
    margin: 0 0 10px;
  }
  
  .job-card .skills span {
    margin-right: 10px;
    background-color: #eee;
    padding: 5px 10px;
    border-radius: 3px;
  }
  