.footer-image-container {
    max-width: 180px; /* Adjust the maximum width of the image */
    margin: 0 auto; /* Center the image */
  }
  
  .footer-image {
    width: 100%;
    height: auto;
  }
  
  
  .footer {
    width: 100%;
    background-color: #241313;
    color: white;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    color: white;
  }
  
  .top-area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .block {
    flex: 1;
    margin: 10px;
    min-width: 200px; /* Ensure blocks have a minimum width */
  }
  
  .block h6 {
    margin-bottom: 10px;
  }
  
  .block ul {
    list-style-type: none;
    padding: 0;
  }
  
  .block ul li {
    margin-bottom: 5px;
  }
  
  .block a {
    color: white; /* Set link color to white */
    text-decoration: none;
  }
  
  .block a:hover {
    color: #dddddd; /* Slightly lighter color on hover */
    transition: color 0.3s ease;
  }
  
  .description {
    margin-top: 10px;
  }
  
  
  
  .bottom-area {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    border-top: 1px solid #c9c3c3;
    padding-top: 10px;
  }
  
  .copyright {
    text-align: center;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .top-area {
      flex-direction: column;
      align-items: center;
    }
  
    .block {
      flex: none;
      width: 100%;
      margin: 10px 0;
      text-align: center;
    }
  
    .block ul {
      padding: 0;
    }
  }
  