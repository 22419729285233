.trending-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
  background-color: #f9f9f9;
  overflow-x: hidden; /* Prevent horizontal scrolling */

}

.trending-item-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 45%;
  text-align: center;
  transition: transform 0.3s;
  margin: 10px;
}

.trending-item-container:hover {
  transform: translateY(-10px);
}

.trending-item-container h2 {
  font-size: 1.5em;
  color: #333;
}

.trending-item-container img {
  width: 50%;
  height: auto;
  border-radius: 10px;
  margin: 20px 0;
}

.trending-item-container p {
  font-size: 1em;
  color: #666;
}

.trending-item-container button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.trending-item-container button:hover {
  background-color: #0056b3;
}

a {
  text-decoration: none;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .trending-container {
    flex-direction: column;
    align-items: center;
    padding: 210px;
    margin-bottom: 30px;

  }

  .trending-item-container {
    max-width: 90%; /* Increased max-width to utilize more space */
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .trending-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .trending-item-container {
    max-width: 100%; /* Ensure items take full width on smaller screens */
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .trending-item-container button {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .trending-container {
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure container takes full width */
    padding: 10px;
  }

  .trending-item-container {
    width: 100%; /* Ensure items take full width */
    padding: 10px;
    box-shadow: none; /* Remove shadow on very small screens */
    margin-bottom: 20px;
  }

  .trending-item-container img {
    width: 100%; /* Make images full width on small screens */
  }

  .trending-item-container button {
    padding: 6px 12px;
    font-size: 14px;
  }
}
