.resume-scan-container {
    display: flex;
    min-height: 100vh;
    margin-left: -10%;
}

.main-content {
    width: 80%;
    padding: 40px;
}

.headerr {
    background: linear-gradient(to right, #4a90e2, #50b3a2);
    color: #fff;
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    margin-left: 180px;
}

.avatar {
    background-color: #e0e0e0;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-right: 20px;
}

.welcome-text span {
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
}

.welcome-text p {
    margin: 5px 0;
    font-size: 14px;
    color: #ffffff;
}

.resume-upload-section {
    text-align: center;
    padding: 20px 0;
    width: 890px;
    background-color: #e8e8e8;
    border-radius: 10px;
    margin-left: 200px;
}

.upload-box {
    margin-top: 20px;
    padding: 30px;
    border: 2px dashed #ccc;
    border-radius: 10px;
    background-color: #fff;
}

.choose-file-button {
    background-color: #ff9900;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.upload-success {
    margin-top: 20px;
}

.experience-dropdown {
    margin-top: 15px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
}

.submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff9900;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.disclaimer {
    color: red;
    font-size: 12px;
    margin-top: 15px;
}

.footerr {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #9c9c9c;
    margin-top: 40px;
}

.credits-section {
    display: flex;
    align-items: center;
}

.buy-credits-button {
    background-color: #ff9900;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.whatsapp-icon {
    /* Add WhatsApp icon styling */
}
