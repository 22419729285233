.job-roles-container {
    text-align: center;
    padding: 20px;
    background: linear-gradient(to right, #eef2f3, #8e9eab);
  }
  
  .job-roles-container h1 {
    font-size: 3em;
    margin-bottom: 10px;
  }
  
  .job-roles-container p {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .roles-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .role-card {
    background: white;
    border-radius: 10px;
    padding: 20px;
    width: 200px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease, border 0.3s ease;
  }
  
  .role-card:hover {
    transform: scale(1.05);
    border: 2px solid rgb(29, 168, 254);
  }
  
  .role-card h2 {
    font-size: 1.2em;
    margin-bottom: 5px;
  }
  
  .role-card p {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  
  .company-logos {
    display: flex;
    justify-content: space-between;
  }
  
  .company-logos img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  /* Mobile Responsive Styles */
  @media (max-width: 768px) {
    .job-roles-container h1 {
      font-size: 2.5em;
    }
  
    .job-roles-container p {
      font-size: 0.9em;
    }
  
    .role-card {
      width: 160px;
      padding: 15px;
    }
  
    .role-card h2 {
      font-size: 1.1em;
    }
  
    .role-card p {
      font-size: 0.8em;
    }
  
    .company-logos img {
      width: 35px;
      height: 35px;
    }
  }
  
  @media (max-width: 480px) {
    .job-roles-container h1 {
      font-size: 2em;
    }
  
    .job-roles-container p {
      font-size: 0.8em;
    }
  
    .role-card {
      width: 100%;
      padding: 10px;
    }
  
    .role-card h2 {
      font-size: 1em;
    }
  
    .role-card p {
      font-size: 0.7em;
    }
  
    .company-logos img {
      width: 30px;
      height: 30px;
    }
  }
  