/* src/components/AboutUs.css */
.about-us {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .about-us h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .about-us p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .explore-button,
  .get-started-button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .get-started-button {
    background-color: #28A745;
  }
  
  .statistics {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .statistics div {
    text-align: center;
  }
  
  .statistics h2 {
    font-size: 2em;
    margin: 0;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .features div {
    text-align: center;
    width: 20%;
  }
  
  .features span {
    font-size: 2em;
  }
  
  .features p {
    font-size: 1em;
  }
  