/* src/components/RefundPolicy.css */
.refund-policy {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .refund-policy h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .refund-policy p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  