/* src/components/ContactUs.css */
.contact-us {
    text-align: center;
    margin: 50px;
  }
  
  .contact-us h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .contact-us p {
    font-size: 1.2rem;
    margin: 5px 0;
  }
  