/* General Styles */
.promo-video {
    width: 60%;
    max-width: 400px; /* Adjust the max width as needed */
    height: auto;
  }
  
  
  .home {
    text-align: center;
    padding: 20px;
  }
  
  h1, h2 {
    margin: 0;
    padding: 0;
  }
  
  .desktop-title {
    display: block;
  }
  
  .mobile-title {
    display: none;
  }
  
  h5 {
    margin-top: 10px;
  }
  
  .data-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .data-item {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    width: calc(33.333% - 40px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .data-item:hover {
    transform: translateY(-5px);
  }
  
  .data-item .profile-img {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .person-info {
    text-align: center;
  }
  
  .view-post-btn {
    display: block;
    text-align: center;
    margin-top: 10px;
    padding: 8px;
    background: #0077b5;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .view-post-btn:hover {
    background: #005582;
  }
  
  .video-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .promo-video {
    width: 40%;
    max-width: 420px;
    border: none;
  }
  
  .apply-now-container {
    margin-top: 20px;
    margin-bottom: 40px; /* Added margin-bottom to create space between button and footer */
    display: flex;
    justify-content: center;
  }
  
  .apply-now-btn {
    background-color: orange;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .apply-now-btn:hover {
    background-color: darkorange;
    transition: background-color 0.3s ease;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .desktop-title {
      display: none;
    }
  
    .mobile-title {
      display: block;
    }
  
    .data-item {
      width: calc(50% - 40px);
    }
  }
  
  @media (max-width: 480px) {
    .data-item {
      width: calc(100% - 40px);
    }
  
    .view-post-btn {
      font-size: 0.9rem;
      padding: 8px;
    }
  
    .promo-video {
      width: 100%;
    }
  
    .apply-now-btn {
      font-size: 1rem;
      padding: 8px 16px;
    }
  }
  