.fulltime-jobs-page .content-container {
  display: flex;
  margin-top: 20px;
    overflow-x: hidden; /* Prevent horizontal scrolling */

}

.fulltime-jobs-page .main-content {
  flex: 1;
  padding: 20px;
}

.fulltime-jobs-page .job-filter-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.fulltime-jobs-page .job-filter-bar .search-bar {
  padding: 10px;
  width: 60%;
  font-size: 16px;
}

.fulltime-jobs-page .job-filter-bar .filter-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.fulltime-jobs-page .job-listings-and-filters {
  display: flex;
}

.fulltime-jobs-page .job-listings {
  flex: 3;
}

.fulltime-jobs-page .job-item {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.fulltime-jobs-page .job-header {
  display: flex;
  align-items: center;
}

.fulltime-jobs-page .company-logo {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
}

.fulltime-jobs-page .job-info {
  flex: 1;
}

.fulltime-jobs-page .job-info h3 {
  margin: 0 0 5px 0;
}

.fulltime-jobs-page .company {
  color: #666;
}

.fulltime-jobs-page .skills {
  margin: 10px 0;
}

.fulltime-jobs-page .skill {
  display: inline-block;
  background-color: #f1f1f1;
  padding: 5px 10px;
  border-radius: 15px;
  margin: 5px 5px 0 0;
  font-size: 14px;
}

.fulltime-jobs-page {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.fulltime-jobs-page p {
  flex: 1;
  text-align: center;
}

.fulltime-jobs-page  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.fulltime-jobs-page .view-details {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

.fulltime-jobs-page .share-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.fulltime-jobs-page .blue-strip {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  bottom: -20px;
  left: -1px;
  right: -1px;
}

.fulltime-jobs-page .filters {
  flex: 1;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  margin-left: 20px;
}

.fulltime-jobs-page .filters h3 {
  margin-top: 0;
}

.fulltime-jobs-page .filters label {
  display: block;
  margin-bottom: 10px;
}

.fulltime-jobs-page .filters input[type="radio"] {
  margin-right: 5px;
}

.fulltime-jobs-page .filters select,
.fulltime-jobs-page .filters input[type="number"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.tab-container{
  display: none;
}

.fulltime-jobs-page .filters .clear-selection {
  background-color: #0381ff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
}
/* Overlay */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup content */
.popup-content {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #aaa;
}

.close:hover {
  color: #000;
}

/* Headline */
.popup-content h3 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

/* Social Icons */
.social-icons {
  margin-bottom: 20px;
}

.social-button {
  background: #f5f5f5;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.social-button:hover {
  background: #ddd;
  transform: translateY(-2px);
}

/* Facebook */
.facebook {
  color: #3b5998;
}

.facebook:hover {
  background: #3b5998;
  color: #fff;
}

/* Twitter */
.twitter {
  color: #1da1f2;
}

.twitter:hover {
  background: #1da1f2;
  color: #fff;
}

/* LinkedIn */
.linkedin {
  color: #0077b5;
}

.linkedin:hover {
  background: #0077b5;
  color: #fff;
}

/* Telegram */
.telegram {
  color: #0088cc;
}

.telegram:hover {
  background: #0088cc;
  color: #fff;
}

/* WhatsApp */
.whatsapp {
  color: #25d366;
}

.whatsapp:hover {
  background: #25d366;
  color: #fff;
}

/* Copy Link */
.copy-link {
  margin-top: 20px;
}

.copy-button {
  background-color: rgb(40, 60, 205);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s, transform 0.3s;
}

.copy-button:hover {
  background-color: rgb(6, 130, 207);
  transform: translateY(-2px);
}



/* Media query for screens smaller than 768px (typical tablet and phone screens) */
@media (max-width: 768px) {
  .fulltime-jobs-page .job-filter-bar .search-bar {
    width: 70%; /* Full width search bar on smaller screens */
    margin-left: -10; /* Remove margin on left for filters on smaller screens */

  }

  .fulltime-jobs-page .filters {
    margin-left: 0; /* Remove margin on left for filters on smaller screens */
  }
  
  .fulltime-jobs-page .job-item {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 50px;
    margin-right: -20px; /* Adjusted margin-right to align properly */
    margin-left: -30px; /* Adjusted margin-left to align properly */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }


  
  .fulltime-jobs-page .blue-strip {
    background-color: #007bff;
    color: white;
    padding: 5px 18px; /* Adjusted padding for better appearance */
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: -50px; /* Adjusted bottom position */
    left: -20px; /* Adjusted left position */
    right: -20px; /* Adjusted right position */
    
  }
  
   
  .fulltime-jobs-page .filters {
    margin-left: 0; /* Remove margin on left for filters on smaller screens */
  }

  .fulltime-jobs-page .job-filter-bar {
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center;
    margin-bottom: 10px;
    margin-right: 30px;
    margin-left: -80px;
    max-width: 99%; /* Limit maximum width */


  }
  
  .fulltime-jobs-page .job-filter-bar .search-bar {
    padding: 10px;
    width: calc(700% - 26px); /* Adjusted width to accommodate margins */
    max-width: 700%; /* Limit maximum width */
    font-size: 19px;
    margin-right: -230px;
    margin-top: 50px;
    margin-left: -160px;



  }
  
  
  .fulltime-jobs-page .job-filter-bar .filter-button {
    padding: 10px 20px;
    font-size: 16px;
    margin-left: 13px;
    cursor: pointer;
    align-items: center;


  }

  .filter-button {
    display: none;
  }

  
  .fulltime-jobs-page .job-listings-and-filters {
    display: flex;
  }
  


  .fulltime-jobs-page .view-details {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: -1px;
    align-items: center;

    text-decoration: none;
  }

  /* Mobile Device Styles */
@media (max-width: 767px) {
  .tab-container {
    display: flex;
    justify-content: center; /* Center align the tabs */
    margin-top: 50px;
    margin-bottom: -50px; /* Adjust margin as needed */
    margin-left: -241px;

  }

  .tab {
    flex: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 0 5px; /* Add horizontal margin for spacing between tabs */
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .tab.active {
    border-color: blue;
    color: blue;
  }
}


  }
  