.pop-up-banner {
  position: fixed;
  bottom: 30px;
  right: 50px;
  width: 300px;
  background-color: #fdcf3a;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
}

.banner-content {
  position: relative;
  text-align: center;
}

h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.close-button {
  position: absolute;
  top: -26px;
  right: -28px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

p {
  margin: 10px 0 20px;
  font-size: 14px;
  color: #555;
}

.join-button {
  display: inline-flex;
  align-items: center;
  background: linear-gradient(90deg, #1e9e22, #00c9a7);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none; /* Ensure it's styled as a button */
  position: relative; /* Needed for the animation */
  overflow: hidden; /* Hide the overflow of the animation */
}

.join-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 200%;
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(45deg);
  animation: slide 2s infinite;
}

@keyframes slide {
  0% {
    top: -50%;
    left: -50%;
  }
  50% {
    top: 150%;
    left: 150%;
  }
  100% {
    top: -50%;
    left: -50%;
  }
}

.join-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Media query for mobile responsiveness */
@media (max-width: 600px) {
  .pop-up-banner {
    width: 90%;
    bottom: 20px;
    right: 5%;
    padding: 15px;
  }

  h2 {
    font-size: 16px;
  }

  p {
    font-size: 12px;
    margin: 8px 0 15px;
  }

  .join-button {
    padding: 8px 15px;
    font-size: 12px;
  }

  .join-button img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
}
