/* General styling for the policy page */
.cancellation-refund-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    padding: 20px;
    margin: 0 auto;
    max-width: 800px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Heading styles */
  .cancellation-refund-policy h1,
  .cancellation-refund-policy h2,
  .cancellation-refund-policy h3,
  .cancellation-refund-policy h4 {
    color: #0056b3;
    margin-bottom: 15px;
  }
  
  /* Paragraph styles */
  .cancellation-refund-policy p {
    margin-bottom: 15px;
  }
  
  /* List styles */
  .cancellation-refund-policy ul {
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .cancellation-refund-policy ul li {
    margin-bottom: 8px;
  }
  
  /* Links styling */
  .cancellation-refund-policy a {
    color: #0056b3;
    text-decoration: none;
  }
  
  .cancellation-refund-policy a:hover {
    text-decoration: underline;
  }
  
  /* Table styles (if needed) */
  .cancellation-refund-policy table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .cancellation-refund-policy table th,
  .cancellation-refund-policy table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .cancellation-refund-policy table th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  /* Button styles (if needed) */
  .cancellation-refund-policy .btn {
    display: inline-block;
    background-color: #0056b3;
    color: white;
    padding: 10px 20px;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .cancellation-refund-policy .btn:hover {
    background-color: #003d80;
  }
  