/* Modern CSS with enhanced styling and animations */

/* Base styles and variables */
:root {
  --primary-color: #2563eb;
  --secondary-color: #1e40af;
  --success-color: #22c55e;
  --background-color: #ffffff;
  --text-color: #1f2937;
  --border-radius: 12px;
  --transition-speed: 0.3s;
  --box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --hover-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

/* Popup Overlay with Backdrop Blur */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
}

/* Banner Host with Modern Scaling */
.bannerhost {
  width: 100%;
  max-width: 700px;
  height: auto;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  transition: transform var(--transition-speed);
}

.bannerhost:hover {
  transform: scale(1.02);
}

/* Modern Button Styles */
.apply-now-button,
.share-button {
  font-size: 0.95rem;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all var(--transition-speed);
  position: relative;
  overflow: hidden;
  background: var(--primary-color);
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: var(--box-shadow);
}

.apply-now-button:hover,
.share-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--hover-shadow);
}

.share-button {
  background: white;
  color: var(--text-color);
  border: 1px solid #e5e7eb;
}

/* Modern Card Design */
.popup-card {
  background: var(--background-color, #ffffff); /* White or customizable */
  padding: 2rem;
  border-radius: var(--border-radius, 10px);
  box-shadow: var(--box-shadow, 0 4px 10px rgba(0, 0, 0, 0.1));
  max-width: 400px;
  width: 90%;
  text-align: center;
  transform: scale(0.95);
  opacity: 0;
  animation: scaleIn 0.3s ease forwards;
}

.referral-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 9px 16px;
  border-radius: 4px;
  font-size: 18px;

  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.referral-button:hover {
  background-color: #0056b3;
}


/* Add color and style to text */
.popup-card h1 {
  font-size: 1.8rem;
  color: #2c3e50; /* Deep Blue */
  margin-bottom: 1rem;
}

.popup-card p {
  font-size: 1rem;
  color: #7f8c8d; /* Muted Gray */
  margin-bottom: 1.5rem;
}

/* Button styles */
.popup-card button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: var(--border-radius, 5px);
  background-color: #3498db; /* Vibrant Blue */
  color: #ffffff; /* White Text */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.popup-card button:hover {
  background-color: #2980b9; /* Darker Blue */
  transform: scale(1.05); /* Slight Zoom */
}

.popup-card button:active {
  background-color: #1c5980; /* Even Darker Blue */
  transform: scale(0.98); /* Slight Compression */
}

/* Animation for the card */
@keyframes scaleIn {
  from {
    transform: scale(0.95);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

/* WhatsApp Button with Modern Design */
.tabe.whatsapp {
  background: #25D366;
  color: white;
  padding: 1rem 1.5rem;
  border-radius: var(--border-radius);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  transition: all var(--transition-speed);
  box-shadow: var(--box-shadow);
}

.tabe.whatsapp:hover {
  background: #128C7E;
  transform: translateY(-2px);
  box-shadow: var(--hover-shadow);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .bannerhost {
    max-width: 100%;
    padding: 0 1rem;
  }

  .popup-card {
    width: 85%;
    padding: 1.5rem;
  }

  .apply-now-button,
  .share-button {
    width: 100%;
    margin: 0.5rem 0;
  }

  .job-summary {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .bannerhost {
    width: 100%;
    padding: 0 0.5rem;
  }

  .apply-now-button,
  .share-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
  }

  .job-info {
    width: 90%;
    margin: 0 auto;
  }

  .job-summary {
    width: 90%;
    margin: 1rem auto;
  }

  .tabe.whatsapp {
    width: 90%;
    margin: 0.5rem auto;
  }
}

/* Tab Container Styles */
.tab-cont {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  max-width: 600px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.tabe {
  padding: 1rem;
  border-radius: var(--border-radius);
  background: white;
  border: 1px solid #e5e7eb;
  transition: all var(--transition-speed);
  cursor: pointer;
  font-weight: 500;
}

.tabe:hover {
  background: #f9fafb;
  transform: translateY(-2px);
}

.tabe.active {
  border-color: var(--primary-color);
  background: #f0f9ff;
  color: var(--primary-color);
}

/* Utility Classes */
.shadow-hover {
  transition: box-shadow var(--transition-speed);
}

.shadow-hover:hover {
  box-shadow: var(--hover-shadow);
}

.scale-hover {
  transition: transform var(--transition-speed);
}

.scale-hover:hover {
  transform: scale(1.02);
}