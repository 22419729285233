/* src/components/ColdMailTemplatesPage.css */

.templates-page {
    background: linear-gradient(135deg, rgba(28, 28, 28, 0.8), rgba(48, 48, 48, 0.8));
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Arial', sans-serif;
}

.page-title {
    text-align: center;
    color: #e0e0e0;
    margin-bottom: 20px;
    font-size: 2em;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tabs button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tabs button:hover {
    background-color: #0056b3;
}

.tabs .active {
    background-color: #0056b3;
}

.templates-list {
    border-top: 1px solid #444;
    padding-top: 20px;
}

.template {
    background-color: #2c2c2c;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    margin: 10px 0;
    padding: 15px;
    transition: filter 0.3s;
}

.template.locked {
    filter: blur(4px);
    pointer-events: none;
}

.template-title {
    margin: 0 0 10px;
    font-size: 1.5em;
    color: #66b3ff;
}

.template-content {
    white-space: pre-wrap; /* Ensures text wraps properly */
    font-family: 'Courier New', Courier, monospace;
    line-height: 1.5;
    color: #ddd;
}

.unlock-section {
    text-align: center;
    margin-top: 20px;
}

.unlock-button {
    background-color: #016fff;
    color: white;
    font-size: 1.3em;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.unlock-button:hover {
    background-color: #261aff;
}
