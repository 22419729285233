.jobs-container {
    display: flex;
  }
  
  .jobs-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #f9f9f9;
    overflow: hidden;
  }
  
  .jobs-content h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .job-list {
    display: flex;
    overflow: hidden;
    position: relative;
    width: calc(100% - 250px); /* Adjust this value based on your sidebar width */
  }
  
  .job-item {
    min-width: 200px;
    margin: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s;
  }
  
  .job-item img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .job-item p {
    margin: 5px 0;
    font-size: 1em;
    color: #666;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .job-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    animation: scroll 20s linear infinite;
  }
  
  .job-item:hover {
    transform: scale(1.05);
  }
  
 
@media (max-width: 768px) {
  .jobs-container {
    flex-direction: column;
  }

  .jobs-content {
    padding: 10px;
  }

  .jobs-content h2 {
    font-size: 1.5em;
  }

  .job-list {
    width: 100%;
    overflow-x: auto;
    padding: 10px 0;
  }

  .job-item {
    min-width: 150px;
    margin: 10px 5px;
  }
}

.int {
  text-align: center;
  margin-top: 20px;
}

.int h1 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.int button {
  background-color: #007BFF; /* Bootstrap primary color */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.1s;
}

.int button:hover {
  background-color: #0056b3; /* Darker shade of primary color */
}