/* src/components/Disclaimer.css */
.disclaimer {
    text-align: left;
    margin: 50px;
    line-height: 1.6;
  }
  
  .disclaimer h1, .disclaimer h2 {
    font-size: 2rem;
    margin-top: 20px;
  }
  
  .disclaimer p {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  