/* src/components/PrivacyPolicy.css */
.privacy-policy {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .privacy-policy h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .privacy-policy h2 {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .privacy-policy h3 {
    font-size: 1.2em;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  
  .privacy-policy h4 {
    font-size: 1.1em;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .privacy-policy p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .privacy-policy ul {
    margin-left: 20px;
  }
  
  .privacy-policy ul ul {
    margin-left: 20px;
  }
  
  .privacy-policy a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  