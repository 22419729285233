/* ATSResumeScore.css */

.ats-score-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.resume-analysis {
    display: flex;
    justify-content: space-between;
    width: 80%;
    max-width: 1200px;
    margin-top: 20px;
}

.left-panel {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.right-panel {
    flex: 1.5;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.upload-section {
    text-align: center;
}

.upload-section h4 {
    margin-bottom: 15px;
}

.upload-section input {
    margin-bottom: 20px;
}

.submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #45a049;
}

.loader {
    text-align: center;
}

.spinner {
    margin: 20px auto;
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #4CAF50;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.score-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.score-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #bcbcbc;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 20px;
}

.score-card h2 {
    font-size: 48px;
    margin-top: 15px;
}

.score-card p {
    margin-top: 10px;
    font-size: 16px;
}

.resume-details {
    margin-top: 20px;
    text-align: center;
}

.resume-details h4 {
    font-weight: bold;
    font-size: 18px;
}

.improvement-section {
    margin-top: 30px;
    text-align: center;
    width: 70%;
    height: 200px; /* Ensure enough height for the bar chart */
}

.improvement-section h4 {
    margin-bottom: 40px;
}

/* Chart CSS */
canvas {
    max-width: 100%;
    height: auto;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .resume-analysis {
        flex-direction: column;
    }

    .left-panel, .right-panel {
        width: 100%;
        margin-bottom: 20px;
    }
}
