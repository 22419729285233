/* General styling with a modern Next.js feel */
.email-list-container {
    width: 80%;
    max-width: 800px;
    margin: 50px auto;
    padding: 40px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: 'Helvetica Neue', sans-serif;
}

.title {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #111827;
    font-weight: 700;
}

.description {
    font-size: 1.1rem;
    color: #4B5563;
    margin-bottom: 20px;
}

.email-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.email-item {
    font-size: 1.2rem;
    padding: 12px 0;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    transition: background-color 0.3s ease;
}

.email-item:hover {
    background-color: #f3f4f6;
}

.email-item:nth-child(even) {
    background-color: #fafafa;
}

.next-name {
    font-weight: bold;
    color: #1f2937;
}

.next-email {
    color: #3b82f6;
}

.blurred {
    color: #ccc;
}

.blurred-text {
    filter: blur(6px);
    font-weight: bold;
    color: #9ca3af;
}

.blurred-section {
    margin-top: 30px;
}

.pay-button {
    background-color: #3b82f6;
    color: white;
    padding: 12px 30px;
    font-size: 1.1rem;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(59, 130, 246, 0.3);
}

.pay-button:hover {
    background-color: #2563eb;
    box-shadow: 0 6px 18px rgba(59, 130, 246, 0.5);
}

/* Additional styles for clarity and consistency */
.next-style {
    background: linear-gradient(145deg, #f3f4f6, #ffffff);
}

.next-title {
    font-family: 'Inter', sans-serif;
}

.next-description {
    max-width: 600px;
    margin: 0 auto 20px;
}

.next-email-item {
    font-family: 'Inter', sans-serif;
    transition: all 0.2s ease-in-out;
}

.next-blurred-section {
    margin-top: 40px;
}

.next-pay-button {
    padding: 15px 40px;
    font-size: 1.2rem;
    font-family: 'Inter', sans-serif;
}
