.applied-page {
  display: flex;
  flex-direction: column;
}

.navbar {
  width: 100%;
}

.content-container {
  display: flex;
  flex: 1;
}

.sidebar {
  width: 200px;
  padding: 10px;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
}

.sidebar button {
  margin: 10px 0;
  padding: 10px;
  background-color: white;
  border: none;
  cursor: pointer;
}

.sidebar .active {
  background-color: #e0e0e0;
}

.main-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.applied-listings {
  display: flex;
  flex-direction: column;
}

.applied-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.applied-card h3 {
  margin: 0 0 10px;
}

.applied-card p {
  margin: 5px 0;
}

.applied-card button {
  padding: 10px;
  margin-top: 10px;
  background-color:  #853bf5; /* Purple background color */
  color: white; /* White text color */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Add cursor pointer */
  transition: background-color 0.3s; /* Smooth transition */
}

.applied-card button:hover {
  background-color: #4209b4; /* Darker purple on hover */
}
