.navbar {
  width: 100%;
  background-color: #fa3a0f;
  color: rgb(15, 75, 255);
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 40px; /* Ensure the height is fixed */
  transition: top 0.1s ease-in-out;
}

.navbar.visible {
  top: 0;
}

.navbar.hidden {
 /*  top: -60px; Adjust this value to match the navbar height */
}

.navbar-logo {
  height: 50px; /* Adjust height as needed */
  margin-right: auto; /* Adjusted to push the logo to the left */
  margin-left: -30px; /* Adjusted to push the logo to the left */
  margin-top: 10px; /* Adjusted to push the logo to the left */


}

.navbar-brand {
  font-size: 1.6rem;
  font-weight: bold;
  color: #f7f4f4;
  cursor: pointer;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.navbar-actions {
  display: flex;
  align-items: center;
}

.update-resume-button {
  background: linear-gradient(90deg, #ff0000, #ff7f7f);
  border: none;
  background-color: #5b5a5a;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  padding-bottom: 12px;
  color: #1f1919;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  margin-right: 2rem;
  
  background: linear-gradient(270deg, #ff1493, #00ff7f, #1e90ff, #ff4500);
  background-size: 800% 800%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradientAnimation 5s ease infinite;
}

.update-resume-button:hover {
  opacity: 0.8;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.navbar-user {
  font-size: 1.3rem;
  margin-right: 4rem;
  color: #fffdfc;
  cursor: pointer;
}

.user-icon {
  width: 35px;
  height: 35px;
}




.dropdown-menu {
  position: absolute;
  top: 60px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.258);
  border-radius: 4px;
  z-index: 1001;
}

.dropdown-item {
  padding: 10px 50px; /* Reduced the left and right padding */
  cursor: pointer;
  font-size: 16px;
  color: black;
  text-align: left;
  border: none;
  background: none;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* Updated coin-bar styling */
.navbar .coin-bar {
  margin-right: 1rem;
  font-size: 1rem;
  color: #ffffff;
  font-weight: bold;
  background: rgb(252, 44, 40);
  padding: 0.5rem 1rem;
  border-radius: 10px;
  transition: background 0.3s ease;
}

.navbar .coin-bar:hover {
  background: linear-gradient(90deg, #ff4500, #1e90ff);
}


/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .navbar {
    padding: 10px 15px; /* Adjust padding for smaller screens */
  }


  .navbar-logo {
    height: 50px; /* Adjust height as needed */
    margin-right: auto; /* Adjusted to push the logo to the left */
    margin-left: -60px; /* Adjusted to push the logo to the left */
    margin-top: -2px; /* Adjusted to push the logo to the left */
  
  
  }

  .navbar-brand {
    
    font-size: 1.4rem; /* Reduce font size for smaller screens */
  }

  .update-resume-button {
    font-size: 17px; /* Adjust button font size for smaller screens */
    margin-top: -0.7rem; /* Reduce margin for smaller screens */

    margin-right: -0.9rem; /* Reduce margin for smaller screens */
  }

  .navbar-user {
    margin-right: 2.1rem; /* Reduce margin for smaller screens */
  
    margin-top: -0.7rem; /* Reduce margin for smaller screens */
  }

  .dropdown-menu {
    right: 10px; /* Adjust dropdown menu position for smaller screens */
  }
}