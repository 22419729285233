/* Gradient Animation */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Sidebar CSS */
.sidebar {
  width: 250px;
  height: 100vh; /* Full height of the viewport */
  padding: 20px;
  margin-top: 23px;
  background-color: #ffffff;
  border-right: 1px solid #ffffff;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.934);
  position: fixed; /* Fixes the sidebar in position */
  top: 0;
  left: 0;
  overflow-y: auto; /* Enables scrolling if the content overflows */
  transition: transform 0.3s ease;
  z-index: 1000; /* Ensure it is above other elements */
}

.sidebar a {
  display: flex;
  align-items: center;
  padding: 13px 0;
  color: #000000;
  font-size: 14px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px; /* Adding slight rounding to the links */
  padding: 10px; /* Added padding for better spacing */
}

.sidebar a:hover {
  color: #007bff;
  background-color: #f4f4f4; /* Light grey background on hover */
}

.sidebar .active {
  background-color: #f7f5f5; /* Light grey background for active link */
  color: #007bff;
  font-weight: bold;
}

.sidebar .icon {
  margin-right: 10px;
  width: 20px;
  font-weight: bold;
  color: #007bff;
  height: 20px;
}

/* Adding some extra styling for better look */
.sidebar a:not(.active):hover .icon {
  filter: brightness(0.7);
}

.sidebar a:not(.active) {
  color: #333; /* Slightly darker grey for inactive links */
}

.sidebar .last-three {
  color: #007bff; /* Permanent blue color */
}

/* Gradient animation for Refer & Earn */
.gradient-button {
  background: linear-gradient(270deg, #fa70ba, #00ff7f, #1e90ff, #ff8d64);
  background-size: 800% 800%;
  animation: gradientAnimation 5s ease infinite;
  color: white; /* Ensure the text remains white */
  font-weight: bold; /* Make the text bold */
}

.sidebar .icons {
  margin-right: 10px;
  width: 20px;
  font-weight: bold;
  color: #ffffff;
  height: 20px;
  font-weight: bold; /* Make the text bold */
}

@media (max-width: 767px) {
  .sidebar {
    width: 50%; /* Adjusted width for smaller screens */
    height: 100vh; /* Full height of the viewport */
    position: fixed;
    top: 0;
    left: -150%; /* Initially hide the sidebar off the left side */
    transform: translateX(0);
    transition: left 0.3s ease, width 0.3s ease; /* Added width transition */
    z-index: 1000; /* Ensure it is above other elements */
  }

.ad-container{
  display:none;
}

  .sidebar.active {
    left: 0; /* Show sidebar when active, sliding in from the left */
  }

  .main-content {
    margin-left: 90%; /* Adjust main content when sidebar is active */
  }
}



/* Sidebar Toggle Button */
.toggle-button {
  display: none; /* Hide by default */
  position: fixed;
  top: 65px;
  left: 10px;
  background-color: #ffffff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1100; /* Make sure it is above the sidebar */
}

/* Show the toggle button on small screens */
@media (max-width: 767px) {
  .toggle-button {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    width: 150px;
  }

  .content {
    padding: 15px;
  }

  .trending-cards,
  .job-cards {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .navbar {
    flex-direction: row;
  }

  .navbar-right {
    flex-direction: row;
  }

  .sidebar {
    width: 200px;
  }

  .content {
    padding: 20px;
  }

  .trending-cards,
  .job-cards {
    flex-direction: row;
  }
}
